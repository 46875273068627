import ShowcaseSection from '@/components/blocks/Showcase'
import { SEO } from '@/components/renderjuice/ShowcaseCard'
import { AnimatedBeamDemo } from '@/components/showcase-cards/AnimatedBeams'

const performanceSections = [
    {
        title: 'Speeds that let your team go 15x to 45x faster',
        description:
            'Second to none performance and speeds for all your renders. Spend less time waiting for renders and free up more time for you and your team.',
        imageSrc: '/images/assets/square.svg',
        imageAlt: '',
    },
    {
        title: 'Built with pro workflows in mind, but with ease of use for everyone.',
        description:
            'Renderjuice was built to extend and work the way you want it to. Flexible for the hobbyist all the way to renowned studios.',
        imageSrc: '/images/assets/square.svg',
        imageAlt: '',
    },
]

const performanceShowcaseHeader = {
    title: 'Performance',
    subtitle: 'Stop wasting time waiting for renders',
    description:
        'Let Renderjuice help you with the performance of the most powerful GPUs on Earth',
}
const insightSections = [
    {
        title: 'Live Preview Monitoring',
        description:
            'See every frame get rendered live as they finish and download them individually or all at once as they finish. ',
        imageSrc: '/images/assets/square.svg',
        imageAlt: '',
    },
    {
        title: 'Actionable Logs and Insights',
        description:
            'Take action with our smart error detection and fix up scenes before committing to a full rendering or even during rendering.',
        imageSrc: '/images/assets/square.svg',
        imageAlt: '',
    },
]

const insightShowcaseHeader = {
    title: 'Insight',
    subtitle: 'Render with visibility and confidence every frame.',
    description:
        'Monitor your render at every step of the process to ensure every frame is the way you want it. ',
}

const blenderShowcaseHeader = {
    title: 'Built for Blender',
    subtitle:
        'We built Renderjuice to support Blender as thoroughly as possible.',
    description:
        'F12 and render with the latest Blender features, customizations, addons, and more so it’s truly like it’s all running on your machine, just way, way faster.',
}

const blenderSections = [
    {
        title: 'Composite Nodes',
        description:
            'Render separate different passes or combine them with composite nodes just like you’d expect',
        imageSrc: '/images/assets/square.svg',
        imageAlt: '',
    },
]

const insightLearnMoreProps = {
    href: '/',
    text: 'Learn more about Insight',
}

const performanceLearnMoreProps = {
    href: '/',
    text: 'Learn more about Performance',
}

const blenderLearnMoreProps = {
    href: '/',
    text: 'More on how we support Blender',
}
export default function PerformanceShowcaseSection() {
    return (
        <section
            id='performance'
            className='mb-4 px-4 text-center md:max-w-5xl md:px-12'
        >
            <ShowcaseSection
                variant='performance'
                headerProps={performanceShowcaseHeader}
                cardProps={performanceSections}
                learnMoreProps={performanceLearnMoreProps}
                cards={[<PerformanceCardOne />, <PerformanceCardTwo />]}
            ></ShowcaseSection>
        </section>
    )
}

const PerformanceCardOne = () => {
    return (
        <div className='grid grid-cols-2 justify-center gap-x-24 gap-y-8 md:gap-x-8 md:gap-y-8 px-8 py-12 md:py-24 max-h-[400px]'>
            <div className='flex flex-col gap-1'>
                <span className='text-center'>Render A</span>
                <SEO max={100} size={65} duration={0.9} />
            </div>
            <div className='flex flex-col gap-1'>
                <span>Render B</span>
                <SEO max={100} size={65} duration={0.6} />
            </div>
            <div className='flex flex-col gap-1'>
                <span>Render C</span>
                <SEO max={100} size={65} duration={1.6} />
            </div>
            <div className='flex flex-col gap-1'>
                <span>Render D</span>
                <SEO max={100} size={65} duration={1.6} />
            </div>
        </div>
    )
}

const PerformanceCardTwo = () => {
    return (
        <div className='px-8 py-24 w-full h-full'>
            <AnimatedBeamDemo />
        </div>
    )
}
